<template>
    <div>
      <h1>Da Rientrare</h1>
      <div class="d-flex flex-row-reverse">
        <div class="p-2"><input type="text" v-model="searchValue" placeholder="Search Value"></div>
        <div class="vr my-2"></div>
        <div class="p-2">
            <div class="btn-group btn-group-sm" role="group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio0" autocomplete="off" checked> 
                <label class="btn btn-sm btn-outline-primary" for="btnradio0" @click="addFilter()">Tutti <span class="badge bg-secondary">{{ items.length }}</span></label>
                
                <template v-for="sede in sedi" :key="sede.id">
                  <input  type="radio" class="btn-check" name="btnradio" :id="'btn'+sede.id" autocomplete="off">
                  <label  class="btn btn-sm btn-outline-primary" :for="'btn'+sede.id"  @click="addFilter(sede.id)">{{sede.nome}} <span class="badge bg-secondary">{{ countInSede(sede.id) }}</span></label>
                </template>
                </div>
        </div>
        <div class="vr my-2"></div>
        <div class="p-2">
          <div class="btn-group btn-group-sm">
            <download-excel :fetch="exportData" :name="'RientrareExport'+'.xls'" :fields="export_fields">
              <a href="#" class="btn btn-sm btn-primary btn-sm bi bi-file-earmark-excel-fill">Esporta</a>
            </download-excel>
          </div>
        </div>
      </div>
      <div></div>
      <DataTable 
        :headers="headers" 
        :items="items"
        :search-value="searchValue"
        :search-field="searchFields"
        :loading="loading_"
        :body-row-class-name="bodyRowClassNameFunction"
        :filter-options="filterOptions"
        :empty-message="'Nessuna veicolo presente'"
        no-hover
      >
        <template #loading>
            <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;" />
        </template>
        <template #item-operation="item">
            <div class="btn-group btn-sm">
                <button type="button" class="btn btn-primary bi bi-eye btn-sm" v-if="item.accettata" @click="$router.push('/accettazione/'+item.id)" />
                <button type="button" class="btn btn-info bi bi-bookmark-fill btn-sm" data-bs-toggle="modal" data-bs-target="#noteM" @click="select(item)" />
              </div>
        </template>
         <template #item-stato="item">
            {{ computeStato(item) }}
        </template>
        <template #item-gis="item">
          <span :title="item.dataRitiro">{{ item.gis }}</span>
        </template>
     
      </DataTable>
      <NoteModal :veicolo="selectedItem" id="noteM" @success="fetchData()"></NoteModal>
      <div class="modal fade modal-lg" id="editModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel"> Richiesta magazzino</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn"></button>
                </div>
                <form @submit.prevent="save">
                <div class="modal-body">
                    <VeicoloDettaglio v-if="selectedItem" :veicolo="selectedItem.veicolo"/>
                        
                        <div class="mb-3 mt-3">
                            <label for="descrizione" class="form-label">Descrizione (obbligatorio)</label>
                            <textarea class="form-control" v-if="selectedItem" v-model="selectedItem.descrizione" disabled></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="magazzino" class="form-label">Magazzino</label>
                            <select class="form-select" aria-label="Default select example" name="magazzino" v-if="selectedItem" v-model="selectedItem.magazzino">
                              <option value="N" >Non richiesto</option>
                              <option value="R">Richiesto</option>
                              <option value="O">Ordinato</option>
                              <option value="A">Arrivato</option>
                            </select>
                        </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary" @click="salva">Salva</button>
                 
                </div>
            </form>
              </div>
            </div>
          </div>


    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import lavoriService from '@/services/lavori.service';
  import VeicoloDettaglio from '@/components/VeicoloDettaglio.vue';
import veicoliService from '@/services/veicoli.service';
import NoteModal from '@/components/NoteModal.vue';
  export default {

    name: 'StoricoHome',
    components: {
        VeicoloDettaglio, NoteModal
    },
    created(){
     
    },
    data() {
        return {
        loading_:true,
        searchValue: "",
        headers: [
          { text: "ID gestionale", value: "id_gestionale" },
          { text: "Telaio", value: "telaio", sortable: true},
          { text: "Targa", value: "targa", sortable: true},
          { text: "Marca", value: "marca", sortable: true},
          { text: "Modello", value: "modello", width: 350, sortable: true},
          { text: "Colore", value: "colore", sortable: true},
          { text: "KM", value:"km", sortable: true},
          { text: "Data Immatricolazione", value: "dataImmatricolazione", sortable: true},
          { text: "GIS", value: "gis", sortable: true},
          { text: "Ubicazione", value: "ubicazione.descrizione", sortable: true},
          { text: "Note", value: "note", width: 150, sortable: true},
          { text: "", value: "operation"}
        ],
        export_fields: {
          "ID Gestionale" : "id_gestionale",
          "Ubicazione" : "ubicazione.descrizione",
          "Telaio" : "telaio",
          "Targa" : "targa",
          "Marca" : "marca",
          "Modello" : "modello",
          "Colore" : "colore",
          "KM" : "km",
          "Data Immatricolazione" : "dataImmatricolazione",
          "Giorni in Stock" : "gis",
          "Note" : "note"
        },
        items: [],

        searchFields: ["id_gestionale", "telaio", "targa", "marca", "modello", "colore", "ubicazione.descrizione"],

        selectedItem: null,

        sedi: [],
        sediLavorabili: [],

        today: new Date().getTime(),

        filterOptions: []
        }
    },
    mounted(){
        this.fetchData();
    },
    computed : {
      
    }, 
    methods : {
        fetchData(){ 
            veicoliService.getAllStock().then(response => {
            this.items = response.data;
            this.items.forEach(it => {
              it.gis = this.difference(it.dataRitiro);
              if (!it.note) it.note = "";
            });
            this.loading_ = false;
            });

            veicoliService.getSedi().then(response => {
              //this.sedi = response.data;
              this.sedi = response.data.filter(s => !s.lavorabile);
              //this.sediLavorabili = response.data.filter(s => s.lavorabile).map(s => s.id);
            });

        },
        exportData(){
            let myitems = this.items
            
            let _getValue_ = ((obj, path) => {
              let paths = path.split('.');

              return paths.reduce((acc, path) => {
                acc = acc[path];
                return acc;
              }, obj)
            });

            // filter elements based on search field
            if (this.searchValue){
              const regex = new RegExp(this.searchValue, 'i');
              myitems = myitems.filter((o) => {
                let searchString = '';
                this.searchFields.forEach((field) => {
                  searchString += _getValue_(o,field);
                });
                return regex.test(searchString);
              });
            }
            
            // filter out items based on filters
            this.filterOptions.forEach((f) => {
              myitems = myitems.filter((o) => {
                if (typeof f.comparison === 'function')
                  return f.comparison(_getValue_(o, f.field), f.criteria);
                switch (f.comparison){
                  case '=': return _getValue_(o, f.field) === f.criteria;
                  default: return _getValue_(o, f.field) === f.criteria;
                }
                
              });
            });            

            return myitems;
        },

        magaz2Desc(code){
            return lavoriService.magazzCode2Descr(code);
        },

        select(item){
          this.selectedItem = item;
        },

        removeFromList(i){
          this.items.splice(this.items.indexOf(i), 1);
        },

        bodyRowClassNameFunction(item){
            if (this.sediLavorabili.includes(item.ubicazione.id_sede)) {
              return 'completato-row';
            }
            return '';
        },
        computeStato(item){
            if (item.accettata) {
              if (item.lavorazione_id) return 'Gestita';
              return 'Completa';
            }
            return 'Da gestire';
        },

        salva(){
            lavoriService.updateLavoro(this.selectedItem.id, {
                magazzino: this.selectedItem.magazzino
            }).then(() => {
                document.getElementById('closeBtn').click();
                this.loading_ = true;
                this.fetchData();
            })
        },

        difference(date1) {
          var day = new Date(date1);
          return Math.ceil((this.today - day.getTime())/ (1000 * 60 * 60 * 24));
        },

        countInSede(id_sede){
          //return 10;
          return this.items.reduce((i,el) => {if (el.ubicazione.id_sede == id_sede) return ++i; return i;},0);
        },

        addFilter(filtro){
          this.headers[8].width = 1;
          this.filterOptions = [];
          if (!isNaN(filtro)){
            this.filterOptions.push({
              field: 'ubicazione.id_sede',
              comparison: '=',
              criteria: filtro
            });
          }
          
        }
    }
  }
  </script>
  <style>

.notAccettato-row  {
  --easy-table-body-row-background-color: #f8d7da;;
  --easy-table-body-row-font-color: #842029;
}

.accettato-row  {
  --easy-table-body-row-background-color: #fff3cd;;
  --easy-table-body-row-font-color: #664d03;;
}
.completato-row  {
  --easy-table-body-row-background-color: #d1e7dd;
  --easy-table-body-row-font-color: #0f5132;;
}
</style>